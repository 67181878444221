<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <div class="pa-6">
        <div class="d-flex mb-4">
          <span class="text-h6">Criar novo produto</span>

          <v-spacer />

          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-form v-model="valid" ref="form">
          <multiple-image-pick
            v-model="form.productImages"
            :required="false"
            :allowVideo="true"
          />

          <custom-input
            v-model="form.productName"
            ref="productName"
            label="Nome do produto*"
            placeholder="Nome do produto"
          />

          <custom-input
            v-model="form.lockDays"
            ref="lockDays"
            label="Quantidade de dias retidos*"
            placeholder="Quantidade de dias retidos"
            @keypress="onlyNumber($event)"
          />

          <custom-input
            v-model="form.sharesSold"
            ref="sharesSold"
            label="Porcentagem de cotas vendidas*"
            placeholder="Porcentagem de cotas vendidas"
            :customRules="_sharesRules"
            @keypress="onlyNumber($event)"
          />

          <custom-input
            v-model="form.timeDescription"
            ref="timeDescription"
            label="Descrição de tempo de contrato"
            placeholder="Descrição de tempo de contrato"
            :required="false"
          />

          <custom-input
            v-model="form.yieldDescription"
            ref="yieldDescription"
            label="Descrição de rendimento"
            placeholder="Descrição de rendimento"
            :required="false"
          />

          <custom-money-input
            v-model="form.minDeposit"
            ref="minDeposit"
            id="minDeposit"
            label="Valor mínimo para depósito"
            placeholder="Valor mínimo para depósito"
            :required="false"
          />

          <custom-money-input
            v-model="form.maxDeposit"
            ref="maxDeposit"
            id="maxDeposit"
            label="Valor máximo para depósito"
            placeholder="Valor máximo para depósito"
            :required="false"
          />

          <custom-text-area
            v-model="form.objective"
            ref="objective"
            label="Objetivo"
            placeholder="Objetivo"
            :required="false"
          />

          <custom-text-area
            v-model="form.benefits"
            ref="benefits"
            label="Benefícios"
            placeholder="Benefícios"
            :required="false"
          />

          <custom-text-area
            v-model="form.operationalPolicy"
            ref="operationalPolicy"
            label="Politica operacional"
            placeholder="Politica operacional"
            :required="false"
          />

          <custom-file-input
            v-model="form.contractDoc"
            ref="contractDoc"
            label="Anexar documento de contrato*"
            placeholder="Anexar documento de contrato"
            accept="application/pdf"
          />
        </v-form>

        <div class="d-flex justify-end">
          <v-btn class="text-none mr-2" plain @click="close()">Cancelar</v-btn>

          <v-btn color="primary" class="text-none" depressed @click="submit()">
            Confirmar
          </v-btn>
        </div>
      </div>

      <loader-hover v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import { displayAlert, onlyNumber, parseNumber } from "@/utils";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      form: {
        productImages: null,
        productName: "",
        lockDays: "",
        sharesSold: 0,
        timeDescription: "",
        yieldDescription: "",
        minDeposit: "0,00",
        maxDeposit: "0,00",
        objective: "",
        benefits: "",
        operationalPolicy: "",
        contractDoc: null,
      },
    };
  },

  computed: {
    _sharesRules() {
      return [
        (v) => v >= 0 || "Valor mínimo de 0",
        (v) => v <= 100 || "Valor máximo de 100",
      ];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        let payload = {
          method: "addNewContrato",
          nomeContrato: this.form.productName,
          diasRetido: this.form.lockDays,
          cotaVendida: this.form.sharesSold,
          descTempodeContrato: this.form.timeDescription,
          descRendimentoContrato: this.form.yieldDescription,
          minDeposito: parseNumber(this.form.minDeposit),
          maxDeposito: parseNumber(this.form.maxDeposit),
          objetivo: this.form.objective,
          listaBeneficios: JSON.stringify(this.form.benefits.split("\n")),
          politicaOperacional: this.form.operationalPolicy,
          docPDF: this.form.contractDoc,
          iconeContrato: "",
          graficoCarteira: "",
          hexCorContrato: "",
        };

        this.form.productImages.forEach((el, i) => {
          payload[`imagens_${i}`] = el;
        });

        await request(payload).then(async (res) => {
          this.displayAlert(res.message);
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err, 1);
      } finally {
        this.loading = false;
      }
    },

    open() {
      this.dialog = true;

      setTimeout(() => {
        this.startForm();
      });
    },

    startForm() {
      this.$refs.minDeposit.handleInput("1.000,00");
      this.$refs.maxDeposit.handleInput("10.000,00");
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
    },

    displayAlert,

    onlyNumber,
  },
};
</script>

<style></style>
