<template>
  <v-container>
    <div class="d-flex align-center mb-4">
      <h1 class="text-h5">
        <span class="work-sans"> Gerenciar Produtos </span>
      </h1>

      <v-spacer />

      <v-btn color="primary" depressed rounded text @click="handleNewProduct()">
        <v-icon small left> mdi-plus </v-icon>
        Novo Produto
      </v-btn>
    </div>

    <loader-content v-if="loading" />

    <section v-else>
      <v-row>
        <v-col
          v-for="(item, i) in products"
          class="zoom"
          cols="12"
          sm="6"
          md="4"
          xl="3"
          :key="i"
        >
          <router-link
            :to="`/products/${item.id}`"
            class="text-decoration-none"
          >
            <v-card class="shadow rounded-lg" height="100%" link>
              <div class="d-flex pa-4">
                <div class="d-flex flex-column pr-2">
                  <div
                    class="primary--text text-h6 font-weight-bold d-flex flex-wrap"
                  >
                    {{ item.productName }}
                  </div>

                  <div class="text-body-2 subtext--text d-flex flex-wrap">
                    {{ item.yieldDescription }}
                  </div>

                  <div class="text-body-2 subtext--text">
                    {{ item.lockDays }} dias retidos / {{ item.sharesSold }}%
                    cotas vendidas
                  </div>
                </div>

                <v-spacer />

                <v-btn icon small @click.stop.prevent="handleEditProduct(item)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </v-card>
          </router-link>
        </v-col>
      </v-row>

      <NewProduct ref="newProduct" @success="getContracts()" />

      <EditProduct ref="editProduct" @success="getContracts()" />
    </section>
  </v-container>
</template>

<script>
import { request } from "@/services";
import { currencyMask } from "@/utils";
import NewProduct from "@/components/products/NewProduct";
import EditProduct from "@/components/products/EditProduct";

export default {
  data() {
    return {
      loading: true,
      editDialog: false,
      products: [],
      selected: null,
    };
  },

  components: {
    NewProduct,
    EditProduct,
  },

  beforeMount() {
    this.getContracts();
  },

  methods: {
    async getContracts() {
      try {
        const payload = {
          method: "listContratos",
        };

        await request(payload).then(async (res) => {
          this.products = res.contratos.map((el) => ({
            id: el.id,
            productImages: el.imagens,
            productName: el.nomeContrato,
            timeDescription: el.descTempodeContrato,
            yieldDescription: el.descRendimentoContrato,
            lockDays: el.diasRetido,
            sharesSold: Number(el.cotaVendida),
            contractURl: el.linkPDF,
            minDeposit: el.minDeposito,
            maxDeposit: el.maxDeposito,
            objective: el.objetivo,
            benefits: el.listaBeneficios,
            operationalPolicy: el.politicaOperacional,
            avaliable: el.avaliable === "1",
          }));
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleNewProduct() {
      this.$refs.newProduct.open();
    },

    handleEditProduct(data) {
      this.$refs.editProduct.open(data);
    },

    currencyMask,
  },
};
</script>

<style></style>
